import React, { useState, useEffect } from "react";

const GameSection = ({ wallet }) => {
  const [bet, setBet] = useState("");
  const [notification, setNotification] = useState("");
  const [recentPlays, setRecentPlays] = useState([]);

  const saveTransaction = (sender, bet, status) => {
    const currentPlays = JSON.parse(localStorage.getItem("recentPlays")) || [];
    const newTransaction = {
      sender,
      amount: bet,
      time: new Date().toLocaleString(),
      status,
    };
    currentPlays.unshift(newTransaction);
    if (currentPlays.length > 10) currentPlays.pop();
    localStorage.setItem("recentPlays", JSON.stringify(currentPlays));
    setRecentPlays(currentPlays);
  };

  useEffect(() => {
    const storedPlays = JSON.parse(localStorage.getItem("recentPlays")) || [];
    setRecentPlays(storedPlays);
  }, []);

  const handleTransaction = async (type) => {
    if (!wallet) {
      setNotification("Кошелек не подключен!");
      return;
    }

    if (isNaN(bet) || parseFloat(bet) <= 0) {
      setNotification("Введите корректную ставку!");
      return;
    }

    try {
      setNotification("Игра началась...");

      const response = await fetch("https:///app.tonflip.com/play", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          address: wallet.account.address, // Адрес игрока
          bet: parseFloat(bet), // Ставка
          choice: type, // Выбор "Heads" или "Tails"
        }),
      });

      const data = await response.json();

      if (data.result === "win") {
        setNotification(`Вы выиграли! Выплата ${bet * 2} TON отправлена.`);
        saveTransaction(wallet.account.address, bet, "Win");
      } else if (data.result === "lose") {
        setNotification("Вы проиграли. Попробуйте снова!");
        saveTransaction(wallet.account.address, bet, "Lose");
      } else {
        setNotification("Ошибка: " + (data.message || "Неизвестная ошибка"));
        saveTransaction(wallet.account.address, bet, "Error");
      }
    } catch (error) {
      setNotification("Ошибка соединения с сервером.");
      console.error(error);
    }
  };

  const setPresetBet = (amount) => {
    setBet(amount.toString());
  };

  return (
    <section id="coin-flip-section">
      <div id="game-options">
        <div id="notification" style={{ color: "red", marginBottom: "10px" }}>
          {notification}
        </div>
        <input
          type="number"
          value={bet}
          onChange={(e) => setBet(e.target.value)}
          placeholder="Введите ставку (TON)"
          className="input"
        />
        <div id="preset-buttons" style={{ margin: "10px 0" }}>
          <button className="button" onClick={() => setPresetBet(0.01)}>
            0.01 TON
          </button>
          <button className="button" onClick={() => setPresetBet(0.1)}>
            0.1 TON
          </button>
          <button className="button" onClick={() => setPresetBet(1)}>
            1 TON
          </button>
        </div>
        <div className="buttons-container">
          <button className="button" onClick={() => handleTransaction("Heads")}>
            Heads
          </button>
          <button className="button" onClick={() => handleTransaction("Tails")}>
            Tails
          </button>
        </div>
      </div>

      <section className="recent-plays">
        <h2>Recent Plays</h2>
        <div className="play-list">
          {recentPlays.length > 0 ? (
            recentPlays.map((play, index) => (
              <div className="play-item" key={index}>
                <p>
                  <span className="player-name">{play.sender}</span> flipped{" "}
                  <span className="amount">{play.amount}</span> TON and{" "}
                  <span className="result">{play.status}</span>.
                </p>
                <span className="time">{play.time}</span>
              </div>
            ))
          ) : (
            <p>Loading recent plays...</p>
          )}
        </div>
      </section>
    </section>
  );
};

export default GameSection;